import React from "react"
import { StaticQuery, Link, graphql } from "gatsby"
import { Date } from "prismic-reactjs"
import { formattedDate } from "../../utils/helper"
const FeaturedBlog = () => {
  return (
    <StaticQuery
      query={graphql`
        query FeaturedBlogPost {
          allPrismicBlogPost(
            sort: { fields: last_publication_date, order: ASC }
            filter: { data: { is_featured: { eq: true } } }
          ) {
            edges {
              node {
                id
                tags
                uid
                url
                type
                data {
                  title {
                    text
                  }
                  short_text {
                    text
                  }
                  is_featured
                  published_date
                  blog_image {
                    alt
                    fluid(maxWidth: 800, maxHeight: 400) {
                      src
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={data => {
        let featured = data.allPrismicBlogPost.edges.filter(
          i => i.node.data.is_featured
        )
        featured =
          featured && featured[0] && featured[0].node ? featured[0].node : null
        if (!featured) return null
        return (
          <>
            <div className="image-container">
              <img
                alt={featured.data.blog_image.alt}
                src={featured.data.blog_image.fluid.src}
              />
            </div>
            <div>
              {featured && <h4>Featured Blog</h4>}
              <Link className="featured-blog-container" to={featured.url}>
                <div className="featured-blog">
                  <div>
                    <p className="hint">
                      {formattedDate(Date(featured.data.published_date))}
                    </p>
                    <h2>{featured.data.title.text}</h2>
                    <p className="short-description">
                      {featured.data.short_text.text}
                    </p>
                    <span className="read-more" to={featured.url}>
                      Read More
                    </span>{" "}
                  </div>
                </div>
              </Link>
            </div>
          </>
        )
      }}
    />
  )
}

export default FeaturedBlog

import React, { useState } from "react"
import { graphql, Link } from "gatsby"
import Layout from "../../components/Layout/Layout"
import "../../styles/pages/blog.css"
import PageTransitionContainer from "../../components/PageTransitionContainer"
import FeaturedBlog from "../../components/Blog/featuredBlog"
// import CategoryTags from "./CategoryTags"
import ReactPaginate from "react-paginate"
import { Date } from "prismic-reactjs"
import { formattedDate } from "../../utils/helper"
import Seo from "../../components/Seo"

const paginate = require("paginate-array")

export const query = graphql`
  query AllBlogPosts($tags: String) {
    allPrismicBlogPost(
      sort: { fields: last_publication_date, order: DESC }
      filter: { tags: { eq: $tags } }
    ) {
      totalCount
      edges {
        node {
          id
          tags
          uid
          url
          type
          data {
            title {
              text
            }
            short_text {
              text
            }
            is_featured
            published_date
            blog_image {
              alt
              fluid(maxWidth: 800, maxHeight: 400) {
                src
              }
            }
          }
        }
      }
    }
  }
`

const BlogCard = (props) => {
  const { node } = props
  const { data, url } = node
  const { blog_image, title, short_text, published_date } = data

  return (
    <div className="blog-card">
      <div className="img-container grow">
        <Link to={url}>
          <figure>
            <img alt={blog_image.alt} src={blog_image.fluid.src} />
          </figure>
        </Link>
      </div>
      <div className="details">
        <h2>{title.text}</h2>
        <p className="short-description">{short_text.text}</p>
        <div className="blog-card__bottom">
          <p className="hint">
            {published_date ? formattedDate(Date(published_date)) : null}
          </p>
          <Link className="read-more" to={url}>
            Read More
          </Link>
        </div>
      </div>
    </div>
  )
}
const numPostsPerPage = 9
const BlogPage = (props) => {
  const { data } = props
  const blogs = data && data.allPrismicBlogPost && data.allPrismicBlogPost.edges

  const [paginateCollection, setPaginateCollection] = useState(
    paginate(blogs, 1, numPostsPerPage)
  )

  // const fakeData = {
  //   id: "ea4afc2a-1b8a-5d97-8413-27de5d90c9d0",
  //   tags: ["Mortgage Advice "],
  //   uid: "who-is-eligible-to-get-a-home-mortgage",
  //   url: "/blog/id/who-is-eligible-to-get-a-home-mortgage",
  //   type: "blog_post",
  //   data: {
  //     title: {
  //       text: "Who Is Eligible To Get A Home mortgage?"
  //     },
  //     short_text: {
  //       text:
  //         "Although Canada has actually tightened the guidelines on home mortgage loaning, we deal with 40+ loan providers amongst whom we can discover various different policies and guidelines which will enable you to obtain a home loan at the very best cost and also terms."
  //     },
  //     is_featured: false,
  //     published_date: "2020-09-10",
  //     blog_image: {
  //       alt: null,
  //       fluid: {
  //         src:
  //           "https://images.prismic.io/ratelockca/5a0ff656-dd80-4a92-9326-3a34b49723ec_laurent-peignault-MTJxRri1UiI-unsplash.jpg?auto=format%2Ccompress&fit=max&q=50&w=800&h=400"
  //       }
  //     }
  //   }
  // }
  const tagPage =
    props && props.pageContext && props.pageContext.tags
      ? props.pageContext.tags
      : 0

  let firstFeatured = null
  return (
    <PageTransitionContainer>
      <Layout location={props.location} page="blog">
        <Seo title="Blog | RateLock" location={props.location} />
        <section id="blog">
          <h1 className="page-heading">Blog</h1>
          <div className="layout-container">
            <div className="left-container">
              {tagPage ? (
                <p>
                  Showing Results Filtered By Tag <strong>{tagPage}</strong>
                </p>
              ) : (
                <FeaturedBlog />
              )}
              <div className="blog-cards">
                {paginateCollection.data.map((i) => {
                  const is_featured = i.node.is_featured
                  if (is_featured && firstFeatured === null) {
                    firstFeatured = i
                    return null
                  }
                  return <BlogCard key={i.node.uid} node={i.node} />
                })}
                {/* <BlogCard node={fakeData} />
                <BlogCard node={fakeData} />
                <BlogCard node={fakeData} />
                <BlogCard node={fakeData} />
                <BlogCard node={fakeData} />
                <BlogCard node={fakeData} />
                <BlogCard node={fakeData} />
                <BlogCard node={fakeData} /> */}
              </div>
              <div id="react-paginate">
                <ReactPaginate
                  id="react-paginate"
                  pageCount={paginateCollection.totalPages}
                  pageRangeDisplayed={1}
                  marginPagesDisplayed={5}
                  onPageChange={(page) => {
                    // setCurrentPage(page)
                    setPaginateCollection(
                      paginate(blogs, page.selected + 1, numPostsPerPage)
                    )
                  }}
                  forcePage={paginateCollection.currentPage - 1}
                  previousLabel={"previous"}
                  nextLabel={"next"}
                  breakClassName={"break-me"}
                  containerClassName={"pagination"}
                  subContainerClassName={"pages pagination"}
                  activeClassName={"active"}
                />
              </div>
            </div>
            {/* <div className="right-container">
              <CategoryTags current={tagPage} />
              <Link to="/getstarted" className="btn">
                Get A Rate Quote
              </Link>
            </div> */}
          </div>
        </section>
      </Layout>
    </PageTransitionContainer>
  )
}

BlogPage.query = query

export default BlogPage
